<template>
  <div @dblclick="handleClick">
    <div class="row items-center">
      <q-badge
        :label="options.label"
        :color="data ? options.selectedColor : options.defaultColor"
      />

      <div
        v-if="data"
        class="text-caption text-capitalize q-ml-sm"
      >
        {{ $t(labels[data.type] || data.type) }}
      </div>
    </div>

    <div v-if="data" class="row text-center text-subtitle1 no-wrap">
      <template
        v-for="(item, i) in path"
        :key="`${item}: ${i}`"
      >
        <div
          class="col q-px-lg q-py-sm"
          style="white-space: pre-wrap !important;"
        >
          <strong>{{ item || 0 }}</strong>
        </div>

        <div
          v-if="i + 1 < path.length"
          class="col q-py-sm"
        >
          -
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasePlaceSection',
  props: {
    options: {
      type: Object,
      default () {
        return {
          label: 'TO',
          defaultColor: 'amber',
          selectedColor: 'positive'
        }
      }
    },
    data: {
      type: [Object, null],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      appOptions: this.$appOptions,
      path: [],
      sub: null,
      labels: {
        employee: 'basket'
      }
    }
  },
  watch: {
    data (value) {
      if (value) {
        this.loadPath(value)
      }
    }
  },
  mounted () {
    if (this.data) {
      this.loadPath(this.data)
    }

    this.sub = this.$eventBus.subscribe('rules', ({ appOptions }) => {
      this.appOptions = appOptions
    })
  },
  unmounted () {
    this.sub.unsubscribe()
  },
  methods: {
    loadPath (place) {
      if (!place.path) {
        place.path = [place.type].map(x => x || 0)
      }

      this.path = [...place.path, (place.placeAlias || '')]
    },
    handleClick () {
      this.$emit('click', this.rawData)
    }
  }
}
</script>
